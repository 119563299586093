
import Vue from 'vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { mapGetters, mapState } from 'vuex';
import { restApi } from '@/components/mixins/restApi';
import { projectPermission } from '@/components/mixins/permissions/project';
import mixins from 'vue-typed-mixins';

import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import WebStorage from '@/common/WebStorage';
import { removeItemFromArrayByValue } from '@/components/helpers';

export default mixins(
	genericHelpers,
	restApi,
).extend({
	name: 'SelectItemsComponent',

	components: {
		ModalButtonSuccess,
		ModalButtonCancel,
		AlertNotificationModal,
	},
	props: {
		shareMode: Boolean,
	},

	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),

		workspace() {
			return this.$store.getters.workspace;
		},

		dynamicNameObject() {
			if (this.activeComponent?.object == 'Projeto') {
				return `Projeto${this.multipleItemSelected ? 's' : ''}`;
			} else if (this.activeComponent?.object == 'Equivalencia') {
				return `Equivalencia${this.multipleItemSelected ? 's' : ''}`;
			} else if (this.activeComponent?.object == 'Workflow') {
				return `Fluxo${this.multipleItemSelected ? 's' : ''}`;
			} else if (this.activeComponent?.object == 'Credencial') {
				return 'Credenciais';
			}
		},
		discardText() {
			if (this.showAlert.mode == 'multipleShare') {
				return 'Cancelar';
			} else if (this.showAlert.mode == 'deleteShare') {
				return 'Manter Compartilhamento';
			}
		},
		keepText() {
			if (this.showAlert.mode == 'multipleShare') {
				return `Seguir com Compartilhamento`;
			} else if (this.showAlert.mode == 'deleteObject') {
				return `Excluir ${this.dynamicNameObject}`;
			} else if (this.showAlert.mode == 'activeObject') {
				return `Ativar ${this.dynamicNameObject}`;
			} else if (this.showAlert.mode == 'inactiveObject') {
				return `Inativar ${this.dynamicNameObject}`;
			}
			// else if (this.showAlert.mode == 'deleteShare') {
			// 	return `Limpar Compartilhamento ${ this.onlyOneItem ? '' : 'em Lote'}`;
			// }
		},
		alertNotificationDescription() {
			if (this.showAlert.mode == 'multipleShare') {
				return `Ao selecionar vários ${this.dynamicNameObject}, você pode atualizar as configurações de compartilhamento simultaneamente. Sendo assim as configurações individuais serão substituídas pelas novas configurações em lote. O que você deseja fazer?"`;
			} else if (this.showAlert.mode == 'deleteObject') {
				return `Ao excluir ${this.dynamicNameObject}, ${this.multipleItemSelected ? 'todos os' : 'o'} ${this.dynamicNameObject} selecionado${this.multipleItemSelected ? 's' : ''} ${this.activeComponent?.object == 'Projeto' ? 'e seus fluxos serão' : this.multipleItemSelected ? 'serão' : 'será'} excluido${this.multipleItemSelected || this.activeComponent?.object == 'Projeto'  ? 's' : ''}}  e você não poderá recuperá-lo${this.multipleItemSelected || this.activeComponent?.object == 'Projeto' ? 's' : ''}. O que deseja fazer?`;
			} else if (this.showAlert.mode == 'activeObject') {
				return `Ao ativar ${this.dynamicNameObject}, ${this.multipleItemSelected ? 'todos os' : 'o'} ${this.dynamicNameObject} selecionado${this.multipleItemSelected ? 's' : ''} ${this.activeComponent?.object == 'Projeto' ? 'e seus fluxos serão' : this.multipleItemSelected ? 'serão' : 'será'} ativado${this.multipleItemSelected || this.activeComponent?.object == 'Projeto' ? 's' : ''}. O que deseja fazer?`;
			} else if (this.showAlert.mode == 'inactiveObject') {
				return `Ao inativar ${this.dynamicNameObject}, ${this.multipleItemSelected ? 'todos os' : 'o'} ${this.dynamicNameObject} selecionado${this.multipleItemSelected ? 's' : ''} ${this.activeComponent?.object == 'Projeto' ? 'e seus fluxos serão' : this.multipleItemSelected ? 'serão' : 'será'} inativado${this.multipleItemSelected || this.activeComponent?.object == 'Projeto' ? 's' : ''}. O que deseja fazer?`;
			}
			// else {
			// 	if (this.activeComponent.object == 'Workflow') {
			// 		return `Ao limpar o compartilhamento ${this.multipleItemSelected ? 'em Lote' : ''} deste${this.multipleItemSelected ? 's' : ''} <b>Fluxo${this.multipleItemSelected ? 's' : ''}</b> e suas <b>Variáveis</b>, todos os usuários perderão suas permissões aos mesmos. O que deseja fazer?`;
			// 	} else if (this.activeComponent.object == `Equivalencia`) {
			// 		return `Ao limpar o compartilhamento ${this.multipleItemSelected ? 'em Lote' : ''} desta${this.multipleItemSelected ? 's' : ''} <b>Equivalência${this.multipleItemSelected ? 's' : ''}</b>, todos os usuários perderão suas permissões ao mesmo. O que deseja fazer?`;
			// 	} else if (this.activeComponent.object == `Credencial`) {
			// 		return `Ao limpar o compartilhamento ${this.multipleItemSelected ? 'em Lote' : ''} desta${this.multipleItemSelected ? 's' : ''} <b>${this.multipleItemSelected ? 'Credenciais' : ''}</b>, todos os usuários perderão suas permissões ao mesmo. O que deseja fazer?`;
			// 	} else if (this.activeComponent.object == `Projeto`) {
			// 		return `Ao limpar o compartilhamento ${this.multipleItemSelected ? 'em Lote' : ''} deste${this.multipleItemSelected ? 's' : ''} <b>Projeto${this.multipleItemSelected ? 's' : ''}</b> e suas <b>Variáveis</b>, todos os usuários perderão suas permissões aos mesmos. O que deseja fazer?`;
			// 	}
			// }
		},
		alertNotificationTitle() {
			if (this.showAlert.mode == 'multipleShare') {
				return 'Compartilhamento em Lote';
			} else if (this.showAlert.mode == 'deleteObject') {
				return `Excluir ${this.dynamicNameObject} ${this.multipleItemSelected ? 'em Lote' : ''}`;
			} else if (this.showAlert.mode == 'activeObject') {
				return `Ativar ${this.dynamicNameObject} ${this.multipleItemSelected ? 'em Lote' : ''}`;
			} else if (this.showAlert.mode == 'inactiveObject') {
				return `Inativar ${this.dynamicNameObject} ${this.multipleItemSelected ? 'em Lote' : ''}`;
			}
			// else {
			// 	return `Limpar Compartilhamento ${ this.onlyOneItem ? '' : 'em Lote'}`;
			// }
		},
		credentialOrEquivalenceObject() {
			if (this.activeComponent?.object == 'Equivalencia' || this.activeComponent?.object == 'Credencial') {
				return true;
			} else {
				return;
			}
		},
		multipleItemSelected() {
			return this.selectedItems.length > 1;
		},
		textActiveSelectedItems() {
			return `Ativar ${ this.dynamicNameObject }`;
		},
		textInactiveSelectedItems() {
			return `Inativar ${ this.dynamicNameObject }`;
		},
		textDeleteSelectedItems() {
			return `Excluir ${ this.dynamicNameObject }`;
		},
		textDuplicateSelectedItems() {
			return `Duplicar ${ this.dynamicNameObject }`;
		},
		textShareSelectedItems() {
			return `Compartilhar ${ this.dynamicNameObject }`;
		},
		textSelectedInfo() {
			return `${this.selectedItems.length + ' ' + this.dynamicNameObject} Selecionados`;
		},
		noItemSelectedText() {
			return `Nenhum ${this.dynamicNameObject} Selecionado`;
		},
		hasNoItemSelected() {
			return this.selectedItems.length == 0;
		},
		onlyOneItem() {
			return this.selectedItems.length == 1;
		},
	},
	data() {
		return {
			showAlert: {
				active: false,
				mode: '',
			},
			activeComponent: {},
			selectedItems: [],
			organization: [],
		};
	},
	created() {
	},
	mounted() {
		this.$root.$on('switchSelectMode', (selectMode) => {
			this.activeComponent = selectMode;
		});
		this.$root.$on('selectAll', (item, selectMode) => {
			this.selectedItems = [];
			this.activeComponent = selectMode;
			this.selectedItems.push(...item);
		});

		this.$root.$on('closeSelectItemsComponent', () => {
			this.cancelAction();
		});


		this.$root.$on('deselectAll', () => {
			this.selectedItems = [];
		});

		this.$root.$on('addOrRemoveItemSelected', (item) => {
			if (this.verifyItemSelected(item)) {
				let index = this.selectedItems.map(items => {
					return items.id;
				}).indexOf(item.id);

				this.selectedItems.splice(index, 1);
			} else {
				this.selectedItems.push(item);
			}
		});
	},
	methods: {
		openActiveModal() {
			this.showAlert.active = true;
			this.showAlert.mode = 'activeObject';
		},
		openInactiveModal() {
			this.showAlert.active = true;
			this.showAlert.mode = 'inactiveObject';
		},
		openShareModalOneOItem() {
			this.openShareModal(...this.selectedItems, this.activeComponent?.object, !this.selectedItems[0].canShare);
		},
		openDuplicateModal() {
			if (this.activeComponent?.object == 'Projeto') {
				this.openModalDuplicateProject();
			} else if (this.activeComponent?.object == 'Workflow') {
				this.openModalDuplicateWorkflow();
			}
		},
		openDeleteModal() {
			this.showAlert.active = true;
			this.showAlert.mode = 'deleteObject';
		},
		openModalDuplicateProject() {
			this.organization = JSON.parse(WebStorage.getItem('cardOrganization'));
			const multi = this.organization?.length > 1 ? true : false;
			const openMode = !multi ? 'oneAccount' : 'multiAccount';
			this.$store.dispatch('ui/openDuplicateProjectModal', {	data: { selectedProjects: this.selectedItems }, mode: openMode });
		},
		openModalDuplicateWorkflow() {
			this.organization = JSON.parse(WebStorage.getItem('cardOrganization'));
			const multi = this.organization?.length > 1 ? true : false;
			const openMode = !multi ? 'oneAccount' : 'multiAccount';
			this.$store.dispatch('ui/openDuplicateWorkflowModal', {	data: { selectedWorkflows: this.selectedItems }, mode: openMode });
		},
		verifyItemSelected(item) {
			return this.selectedItems.find(selectedItem => selectedItem.id === item.id);
		},

		openAlertMultipleShare() {
			this.showAlert.active = true;
			this.showAlert.mode = 'multipleShare';
		},
		closeAlert() {
			this.showAlert.mode = '';
			this.showAlert.active = false;
		},
		async activeProject() {
			const selectedItemsId = this.selectedItems.map(item => item.id);
			this.$store.commit('activeAlert', {
				message: `Iniciamos a ativação do${this.multipleItemSelected ? 's' : ''} projeto${this.multipleItemSelected ? 's' : ''}, isso poderá levar alguns minutos. Informaremos quando finalizar.`,
			});
			try {
				await this.restApi().activeMultipleProjects(this.workspace.id, selectedItemsId).then((response) => {

					this.$store.commit('activeAlert', {
						message: this.multipleItemSelected ? "Todos os projetos e seus fluxos foram ativados." : 'O Projeto e seus fluxos foram ativados com sucesso.',
					});
				});

				this.cancelAction();
				this.$root.$emit('reloadProject');
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: "Ocorreu um erro na tentativa de ativação, tente novamente.",
					status: 'error',
				});
				console.error(e);
			}

		},
		async inactiveProject() {
			const selectedItemsId = this.selectedItems.map(item => item.id);
			this.$store.commit('activeAlert', {
				message: `Iniciamos a desativação do${this.multipleItemSelected ? 's' : ''} ${this.dynamicNameObject}, isso poderá levar alguns minutos. Informaremos quando finalizar.`,
			});
			try {
				await this.restApi().inactiveMultipleProjects(this.workspace.id, selectedItemsId).then((response) => {

					this.$store.commit('activeAlert', {
						message: this.multipleItemSelected ? "Os projetos e seus Fluxos foram desativados com sucesso" : 'O Projeto e seus fluxos foram desativados com sucesso.',
					});
				});

				this.cancelAction();
				this.$root.$emit('reloadProject');
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: "Ocorreu um erro na tentativa de desativação, tente novamente.",
					status: 'error',
				});
				console.error(e);
			}

		},
		async deleteProject() {
			const selectedItemsId = this.selectedItems.map(item => item.id);
			this.$store.commit('activeAlert', {
				message: `Iniciamos a exclusão do${this.multipleItemSelected ? 's' : ''} ${this.dynamicNameObject}, isso poderá levar alguns minutos. Informaremos quando finalizar.`,
			});
			try {
				await this.restApi().deleteProjects(this.workspace.id, { projetoIds: selectedItemsId }).then((response) => {

					this.$store.commit('activeAlert', {
						message: this.multipleItemSelected ? "Todos os projetos e seus fluxos foram excluídos." : 'O Projeto e seus fluxos foram excluido com sucesso.',
					});
				});

				this.$root.$emit('reloadProject');
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: "Ocorreu um erro na tentativa de exclusão, tente novamente.",
					status: 'error',
				});
				console.error(e);
			}
			this.cancelAction();
		},
		async activeWorkflow() {
			const selectedItemsId = this.selectedItems.map(item => item.id);
			this.$store.commit('activeAlert', {
				message: `Iniciamos a ativação do${this.multipleItemSelected ? 's' : ''} ${this.dynamicNameObject}, isso poderá levar alguns minutos. Informaremos quando finalizar.`,
			});
			try {
				await this.restApi().activeMultipleProjects(this.workspace.id, selectedItemsId).then((response) => {

					this.$store.commit('activeAlert', {
						message: this.multipleItemSelected ? "Todos os fluxos foram ativados." : 'O fluxo foi ativado com sucesso.',
					});
				});

				this.cancelAction();
				this.$root.$emit('reloadList');
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: "Ocorreu um erro na tentativa de ativação, tente novamente.",
					status: 'error',
				});
				console.error(e);
			}

		},
		async inactiveWorkflow() {
			const selectedItemsId = this.selectedItems.map(item => item.id);
			this.$store.commit('activeAlert', {
				message: `Iniciamos a inativação do${this.multipleItemSelected ? 's' : ''} ${this.dynamicNameObject}, isso poderá levar alguns minutos. Informaremos quando finalizar.`,
			});
			try {
				await this.restApi().inactiveMultipleWorkflows(this.workspace.id, selectedItemsId).then((response) => {

					this.$store.commit('activeAlert', {
						message: this.multipleItemSelected ? "Todos os fluxos foram inativados." : 'O fluxo foi inativado com sucesso.',
					});
				});

				this.cancelAction();
				this.$root.$emit("reloadDetail");
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: "Ocorreu um erro na tentativa de inativação, tente novamente.",
					status: 'error',
				});
				console.error(e);
			}

		},
		async deleteMultipleWorkflow() {
			const selectedItemsId = this.selectedItems.map(item => item.id);
			this.$store.commit('activeAlert', {
				message: `Iniciamos a exclusão do${this.multipleItemSelected ? 's' : ''} fluxo${this.multipleItemSelected ? 's' : ''}, isso poderá levar alguns minutos. Informaremos quando finalizar.`,
			});
			try {
				await this.restApi().deleteMultipleWorkflow(this.workspace.id, { workflowIds: selectedItemsId }).then((response) => {

					this.$store.commit('activeAlert', {
						message: this.multipleItemSelected ? "Todos os fluxos foram excluídos." : 'O fluxo foi excluido com sucesso.',
					});
				});

				this.$root.$emit("reloadDetail");
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: "Ocorreu um erro na tentativa de exclusão, tente novamente.",
					status: 'error',
				});
				console.error(e);
			}
			this.cancelAction();
		},
		async confirmAction() {
			if (this.showAlert.mode == 'multipleShare') {
				const selectedItemsId = this.selectedItems.map(item => item.id);
				this.openShareModal({id: selectedItemsId}, this.activeComponent?.object);
			} else if (this.showAlert.mode == 'deleteObject') {
				if (this.activeComponent?.object == 'Projeto') {
					this.deleteProject();
				} else if (this.activeComponent?.object == 'Workflow') {
					this.deleteMultipleWorkflow();
				}

			} else if (this.showAlert.mode == 'activeObject') {
				if (this.activeComponent?.object == 'Projeto') {
					this.activeProject();
				} else if (this.activeComponent?.object == 'Workflow') {
					this.activeWorkflow();
				}
			} else if (this.showAlert.mode == 'inactiveObject') {
				if (this.activeComponent?.object == 'Projeto') {
					this.inactiveProject();
				} else if (this.activeComponent?.object == 'Workflow') {
					this.inactiveWorkflow();
				}
			}
			// if (this.showAlert.mode != 'deleteShare') {

			// } else {
			// 	try {
			// 		await this.restApi().deleteShare(this.workspace.id, { objetoId: selectedItemsId, tipoObjeto: this.activeComponent.object});
			// 		this.$store.commit('activeAlert', {
			// 			message: "Compartilhamento limpado com sucesso.",
			// 		});

			// 		this.$root.$emit('iconChange', { objetoId: selectedItemsId, tipoObjeto: this.activeComponent.object, deleteShare: true });
			// 		this.$root.$emit('reloadList');
			// 	} catch (e) {
			// 		console.error(e);
			// 	}

			// 	this.cancelAction();
			// }

			this.closeAlert();
		},
		cancelAction() {
			this.$root.$emit('cancelSwitchMode');
			this.activeComponent.active = false;
			this.showAlert.mode = '';

			setTimeout(() => {
				this.selectedItems = [];
			}, 500);
		},
	},
	watch: {
		'$route'(route) {
			this.cancelAction();
		},
	},
});
