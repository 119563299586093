
import { ref } from 'vue';
import { mapActions } from 'vuex';
import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';
import { removeItemFromArrayByValue } from '@/components/helpers';
import Vue from 'vue';
import InfoBox from '@/components/InfoBox.vue';

import Modal from '@/components/ModalAliare.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import AliareSelect from '@/components/AliareSelect.vue';
import AliareInput from '@/components/AliareInput.vue';
import DuplicateProject from '@/components/ModalDuplicateProject.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

import WebStorage from '@/common/WebStorage';
import { PERMISSION_IDS, CREATE_EDIT_PROJECT_MODAL_KEY, DUPLICATE_PROJECT_MODAL_KEY} from '@/constants';
import { searchPermissions } from '@/components/helpers';
import { projectPermission } from '@/components/mixins/permissions/project';
import { genericHelpers } from '@/components/mixins/genericHelpers';

import { array } from 'yup/lib/locale';

export default mixins(
	restApi,
	projectPermission,
	genericHelpers,
)
.extend({
	name: 'CardFlow',
	components: {
		Modal,
		ModalButtonSuccess,
		ModalButtonCancel,
		DuplicateProject,
		AliareSelect,
		AliareInput,
		InfoBox,
		AlertNotificationModal,
	},
	// @ts-ignore
	emits: ['reload'],
	props: {
		arrayCardData: Array,
	},
	data() {
		return {
			deleteData: {},
			showAlert: false,
			editAux: [],
			show: false,
			message: '',
			activeBtnEdit: false,
			selected: '',
			selectedAccountData: '',
			organization: [],

			total: 'Total',
			alert: 'projectDetailing.alert',
			success: 'projectDetailing.success',
			error: 'projectDetailing.failure',
			titleBody: 'project.selectResultsLabel',
			activeStatus: 'activeDeactivateButton.active',
			inactiveStatus: 'activeDeactivateButton.inactive',

			textCreateFlow: 'projectDetailing.btn_1',
			textDuplicateProject: 'project.tect_2',
			textDeleteProject: 'project.textDeleteProject',

			viewOption: false,
			tokenData: '',
			isLoading: false,
			selectMode: false,
			arrayProjectsSelected: [],
			renderComponent: true,
		};
	},
	computed: {
		workspace() {
			return this.$store.getters.workspace;
		},
		allProjectsSelected() {
			return this.projectsAbleToSelect == this.arrayProjectsSelected.length;
		},
		projectsAbleToSelect() {
			return this.arrayCardData.filter(project => project.permissao == 'Editar').length;
		},
		partialProjectsSelected() {
			return this.projectsAbleToSelect != this.arrayProjectsSelected.length && this.arrayProjectsSelected.length > 0;
		},
	},
	created() {
		this.organization = JSON.parse(WebStorage.getItem('cardOrganization'));
		this.tokenData = WebStorage.getItem('tokenData');
	},
	mounted() {
		this.$root.$on('switchSelectMode', () => {
			this.selectMode = !this.selectMode;
		});
		this.$root.$on('cancelSwitchMode', () => {
			this.arrayCardData.forEach((form) => {
				form.selectedProject = false;
			});
			this.arrayProjectsSelected = [];
			this.selectMode = false;
		});

		this.$root.$on('iconChange', (change) => {
			this.iconChange(change);
		});
	},
	methods: {
		...mapActions('auth', ['revokeTokens']),
		...mapActions('aliareAccount', ['getPermissionFromUser']),
		...mapActions('project', [
			'getProjectDetails',
			'getProject',
			'delete',
			'patchActive',
			'patch',
		]),
		emitSelectAll() {
			this.isLoading = true;
			if (this.allProjectsSelected) {
				this.arrayCardData.forEach((form) => {
					form.selectedProject = false;
				});
				this.arrayProjectsSelected = [];
				this.isLoading = false;
				this.$root.$emit('deselectAll');
			} else {
				this.$emit('selectAllProjects');
			}
		},
		async forceRerender() {
			this.renderComponent = false;

			await Vue.nextTick();

			this.renderComponent = true;
    },

		toggleSelectProject(form, emitSwitch) {
			if (form.permissao != 'Editar') return;

			this.$root.$emit('addOrRemoveItemSelected', JSON.parse(JSON.stringify({
				...form,
				canShare: this.canShareProject(form, form.ativo),
			})));
			form.selectedProject = !form?.selectedProject;

			if (form.selectedProject) {
				this.arrayProjectsSelected.push(form);
			} else {
				let index = this.arrayProjectsSelected.map(items => {
					return items.id;
				}).indexOf(form.id);

				this.arrayProjectsSelected.splice(index, 1);
			}
			if (emitSwitch) {
				this.$root.$emit('switchSelectMode', {active: JSON.parse(JSON.stringify(form.selectedProject)), object: 'Projeto'});
			}
			this.forceRerender();
		},
		async openModalAlert() {			
			this.showAlert = true;
		},

		newWorkflow(id) {
			this.$router.push({ name: 'NodeViewNew', params: { id } });
		},
		acessDetails (form) {
			if (this.selectMode) {
				this.toggleSelectProject(form);
				return;
			}
			this.$router.push({
				name: 'projectDetailing',
				params: { id: form.id },
			})
		},

		onSelect(type: string, dataAccount) {
			this.selected = type;
			this.selectedAccountData = dataAccount;
		},
		oneOptionSelected(payload, dataAccount) {
			this.selected = payload;
			this.selectedAccountData = dataAccount;
		},
		deleteProject(deleteData) {
			const deleteProjectName = deleteData.form.nome;
			this.delete({workspaceId: this.workspace.id, projetoId: deleteData.form.id})
				.then((aux) => {
					this.showAlert = false;
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('project.modalDelete.projectDeleteSuccess', { interpolate: {deleteProjectName} }),
					});

					const removed = this.arrayCardData.splice(deleteData.index, 1);
					this.validationAccessProject();
				})
				.catch((error) => {
					const deleteError = error.response?.data?.notifications;
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('project.modalDelete.deleteError', { interpolate: {deleteError} }),
						status: 'error',
					});
				});
		},
		async changeActiveStatus(form, index) {
			try {
				const payload = { workspaceId: this.workspace.id, projetoId: form.id, ativo: !form.ativo };
				const result = await this.patchActive(payload);

				form.ativo = result.data.ativo;
				const ProjectName = form.nome;

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText(result.data.ativo ? 'project.sucessActive' : 'project.sucessInactive', { interpolate: { ProjectName } }),
				});

				this.$emit('reload');
				this.validationAccessProject();

				return form.ativo;
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('project.errorActiveOrInactive', { interpolate: { ErrorStatus: error } }),
					status: 'error',
				});

				return form.ativo;
			}
		},
		showModals(form, key) {
			if (key === 'createEditProject') {
				this.$store.dispatch('ui/openCreateEditProjectModal', {	data: form, mode: 'edit' });
			}
		},
		openModalDuplicateProject(projectId, multi) {
			const openMode = !multi ? 'oneAccount' : 'multiAccount';
			this.$store.dispatch('ui/openDuplicateProjectModal', {	data: { projectId }, mode: openMode });
		},
		iconChange(change) {
			if (change.tipoObjeto === 'Projeto') {
				change.objetoId.forEach(id => {
					const card = this.arrayCardData?.find(card => card.id === id);
					if (card) {
						card.compartilhado = change?.deleteShare ? !card.compartilhado : true;
					}
				});
    		}	
		},
	},
	watch: {
		arrayCardData(newValue, oldValue) {
			if (!newValue || !newValue.length) return;
			
			const projectsSelected = this.arrayCardData.filter(project => project.selectedProject);
			if (projectsSelected.length == 0) {
				return;
			} 
			this.arrayProjectsSelected = JSON.parse(JSON.stringify(projectsSelected));
			this.isLoading = false;
			this.$root.$emit('selectAll', JSON.parse(JSON.stringify(projectsSelected)), {active: true, object: 'Projeto'});
		},
	},
});
