import { render, staticRenderFns } from "./DataSource.vue?vue&type=template&id=09f2b91a&scoped=true&"
import script from "./DataSource.vue?vue&type=script&lang=ts&"
export * from "./DataSource.vue?vue&type=script&lang=ts&"
import style0 from "./DataSource.vue?vue&type=style&index=0&id=09f2b91a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09f2b91a",
  null
  
)

export default component.exports