
export default {
	props: {
		noText: {
			type: Boolean,
			default: false,
		},
		titleButton: {
			type: String,
			default: '',
		},
		plusIcon: {
			type: Boolean,
			default: false,
		},
		shareIcon: {
			type: Boolean,
			default: false,
		},
		restoreIcon: {
			type: Boolean,
			default: false,
		},
		copyIcon: {
			type: Boolean,
			default: false,
		},
		retryIcon: {
			type: Boolean,
			default: false,
		},
		editIcon: {
			type: Boolean,
			default: false,
		},
		saveIcon: {
			type: Boolean,
			default: false,
		},
		shovelIcon: {
			type: Boolean,
			default: false,
		},
		sendIcon: {
			type: Boolean,
			default: false,
		},
		active: {
			type: Boolean,
			default: false,
		},
		alwaysActive: {
			type: Boolean,
			default: false,
		},
		externIcon: {
			type: Boolean,
			default: false,
		},

		forwardIcon: {
			type: Boolean,
			default: false,
		},
		backwardIcon: {
			type: Boolean,
			default: false,
		},
		redoIcon: {
			type: Boolean,
			default: false,
		},
		eyeIcon: {
			type: Boolean,
			default: false,
		},
		applyIcon: {
			type: Boolean,
			default: false,
		},
		disapproveIcon: {
			type: Boolean,
			default: false,
		},
		filterIcon: {
			type: Boolean,
			default: false,
		},
		workspaceIcon: {
			type: Boolean,
			default: false,
		},
		profileIcon: {
			type: Boolean,
			default: false,
		},
		integrationIcon: {
			type: Boolean,
			default: false,
		},
		publishIcon: {
			type: Boolean,
			default: false,
		},
		importIcon: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String,
			default: '111',
		},
	},
	methods: {
		clickTrigger(e) {
			this.$emit('click', e);
		},
	},
	computed: {
		btnStyles() {
		return {
        "width": `${this.width}px`,
      };
		},
	},
};
