
import Table from '@/components/Table.vue';
import FilterBar from '@/components/FilterBar.vue';
import FilterIcon from '@/components/FilterIcon.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';
import { removeItemFromArrayByValue } from '@/components/helpers';

import moment from 'moment';
import WebStorage from '@/common/WebStorage';
import { mapActions, mapGetters, mapState } from 'vuex';

export default mixins(restApi).extend({
	components: {
		Table,
		FilterBar,
		FilterIcon,
		SelectFilter,
		BackgroundView,
		InteractionMenu,
		ModalButtonCancel,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	data() {
		return {
			showAlert: {
				active: false,
				data: {},
				mode: '',
			},
			approveAlert: false,
			isLoadingPage: false,
			isLoadingTable: false,
			scrollInfinite: true,

			page: 1,
			pageSize: 15,

			listRequests: [],
			organizationInfo: JSON.parse(WebStorage.getItem('accountInfo')),

			filtering: false,
			filtersActive: {},
			clearfiltering: false,
			queryResults:	typeof this.$route.query.status == 'string'	? [this.$route.query.status] : this.$route.query.status || [],
		};
	},
	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),
		resultText() {
			if (typeof this.queryResults === 'string') {
				return this.queryResults;
			}
			const length = this.queryResults.length;
			return length === 1 ? this.queryResults[0] : length === 0	? 'Status' : `${this.queryResults[0]} +${length - 1}`;
		},
		textTooltip() {
			return 'Assim que o processo de aquisição for concluído, a importação desta Integração ficará disponível';
		},

		discardModalText() {
			if(this.approveAlert){
				return this.$locale.baseText('Aprovar solicitação');
			}
			return this.$locale.baseText('Reprovar solicitação');
		},
		alertNotificationTitle() {
			if(this.approveAlert){
				return this.$locale.baseText('Aprovar solicitação');
			}
			return this.$locale.baseText('Reprovar solicitação');
		},
		alertNotificationDescription() {
			if(this.approveAlert){
				return `Ao prosseguir o status dessa solicitação será <b>alterado</b> e <b>será visto pelo cliente</b>. O que você deseja fazer?`;
			}
			return `Ao prosseguir o status dessa solicitação será <b>alterado</b> e <b>não será visto pelo cliente</b>. O que você deseja fazer?`;
		},
	},
	methods: {
		async initPageRequestsReceived() {
			const applyPage = WebStorage.getItem('applyPage');

			if (applyPage == 'received') {
				this.$router.replace({query: { ...WebStorage.getItem('setFilters') }}).catch(() => {});
			}

			await this.getRequestsReceived();
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY');
			return localDate;
		},
		openAlert(integration, approve, mode) {
			this.approveAlert = approve;
			this.showAlert.active = true;
			this.showAlert.mode = mode;
			this.showAlert.data = integration;

		},
		async approveOrDisapproveRequest(data, action) {

			try {
				await this.restApi().postStatusIntegration(data.id, action);

				const mode = action == 'Aprovar' ? 'aprovada' : 'reprovada';
				this.$store.commit('activeAlert', {
					message: `A Solicitação foi ${mode} com sucesso.`,
				});

				this.showAlert.active = false;
				this.initPageRequestsReceived();
			} catch (error) {
				this.$store.commit('activeAlert', {
					message:  error.notifications,
					status: 'error',
				});
				console.log('error', error);
			}

		},

		async filterBySearch(search) {
			this.filtering = search.length < 1 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length < 1 ? '' : search);
		},
		async filterByResult(query) {
			this.queryResults = query;
			this.addOrRemoveFilter(this.filtersActive, 'filterResults', query);
			this.filtering = !this.queryResults.length;
		},
		async filterTable(filters) {
			try {
				this.isLoading = true;
				const routeQuery = this.$route.query;
				const payload = {
					tituloIntegracao: filters?.filterSearch || routeQuery?.tituloIntegracao,
					status: filters?.filterResults || routeQuery?.status,
					ignorarFiltroPorTenant: true,
				};

				this.$router.replace({query: { ...payload }}).catch(() => {});

				const dataFilter = await this.restApi().getImportRequests(payload);
				await this.applyFilter(dataFilter, payload);
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications,
					status: 'error',
				});
				this.clearfiltering = true;
			} finally {
				this.isLoading = false;
			}
		},
		async applyFilter(dataFiltered, query) {
			this.filtering = true;
			this.clearfiltering = true;
			this.listRequests = dataFiltered;

			WebStorage.setItem('setFilters', query);
			WebStorage.setItem('applyPage', 'received');
		},
		async clearFilter() {
			this.filtering = false;
			this.filtersActive = {};
			this.clearfiltering = false;
			this.scrollInfinite = true;
			this.queryResults = [];

			await this.$router.replace({'query': null}).catch(() => {});

			WebStorage.removeItem('applyPage');
			WebStorage.removeItem('setFilters');
			this.initPageRequestsReceived();
		},
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		async getRequestsReceived() {
			this.isLoadingTable = true;
			const payload = {
				page: 1,
				size: this.pageSize,
				...this.$route.query,
				ignorarFiltroPorTenant: true,
			};

			try {
				this.listRequests = await this.restApi().getImportRequests(payload);
				console.log('o que vem aqui: ', this.listRequests);

				if(this.listRequests?.length < 15) {
					this.scrollInfinite = false;
				}
			} catch (error) {
				this.$store.commit('activeAlert', {
					message:  error.notifications,
					status: 'error',
				});
				console.log('error', error);
			}
			this.isLoadingTable = false;
		},
		async pagination() {
			this.page++;
			const payload = {
				page: this.page,
				size: this.pageSize,
				...this.$route.query,
				ignorarFiltroPorTenant: true,
			};

			try {
				const response = await this.restApi().getImportRequests(payload);
				this.listRequests.push(...response);

				if (response.length < 15) {
					this.scrollInfinite = false;
				}
			} catch (error) {
				this.$store.commit('activeAlert', {
					message:  error.notifications,
					status: 'error',
				});
			}
		},
	},
	async created() {
		this.initPageRequestsReceived();
	},
	mounted() {},
});
