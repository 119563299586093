
import Table from '@/components/Table.vue';
import FilterBar from '@/components/FilterBar.vue';
import FilterIcon from '@/components/FilterIcon.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';
import { removeItemFromArrayByValue } from '@/components/helpers';
import { integrationsPermission } from '@/components/mixins/permissions/integrations';

import moment from 'moment';
import WebStorage from '@/common/WebStorage';
import { previousRoute } from '../router/index.ts';
import { mapActions, mapGetters, mapState } from 'vuex';

export default mixins(
	restApi,
	integrationsPermission,
).extend({
	components: {
		Table,
		FilterBar,
		FilterIcon,
		SelectFilter,
		BackgroundView,
		InteractionMenu,
		RoundPhotoFrame,
		ModalButtonCancel,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	name: 'Integrations',
	data() {
		return {
			showAlert: {
				active: false,
				data: {},
			},
			publishAlert: false,
			suspendAlert: false,
			isLoadingPage: false,
			scrollInfinite: true,
			isLoadingTable: false,

			listIntegrations: [],
			listIntegrationsTemp: [],
			organizationInfo: JSON.parse(WebStorage.getItem('accountInfo')),

			page: 1,
			pageSize: 15,
			applyPage: '',
			filtering: false,
			filtersActive: {},
			clearfiltering: false,
			queryResults: typeof this.$route.query.status == 'string' ? [this.$route.query.status] : this.$route.query.status || [],
		};
	},
	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),
		workspace() {
			return this.$store.getters.workspace;
		},

		discardModalText() {
			if(this.publishAlert){
				return this.$locale.baseText('Publicar Integração');
			} else if(this.suspendAlert){
				return this.$locale.baseText('Manter Integração Publicada');
			}
			return this.$locale.baseText('Manter Integração');
		},
		keepModalText() {
			if(this.publishAlert){
				return this.$locale.baseText('Cancelar');
			} else if(this.suspendAlert){
				return this.$locale.baseText('Suspender Integração');
			}
			return this.$locale.baseText('Deletar Integração');
		},
		alertNotificationTitle() {
			if(this.publishAlert){
				return this.$locale.baseText('Publicar Integração');
			} else if(this.suspendAlert){
				return this.$locale.baseText('Suspender Publicação');
			}
			return this.$locale.baseText('Exclusão de Intregração');
		},
		alertNotificationDescription() {
			if(this.publishAlert){
				return `Ao prosseguir a  integração será <b>publicada</b> e <b>será vista e acessada pelo cliente</b>. O que você deseja fazer?`;
			} else if(this.suspendAlert){
				return `Ao prosseguir a  integração será <b>suspensa</b> e <b>não poderá ser acessada pelo cliente</b>. Ela estará disponível na listagem para caso queria publicá-la novamente. O que você deseja fazer?`;
			}
			return `Ao prosseguir a integração será <b>excluída</b> e você <b>não poderá recuperá-la</b>. O que você deseja fazer?`;
		},
		resultText() {
			if (typeof this.queryResults === 'string') {
				return this.queryResults;
			}
			const length = this.queryResults.length;
			return length === 1 ? this.queryResults[0] : length === 0 ? 'Status' : `${this.queryResults[0]} +${length - 1}`;
		},
		emptyList() {
			return 'Não há execuções na fila. A medida que forem adicionadas, as informações serão apresentadas aqui.'
		},
	},
	methods: {

		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},
		openAlert(publish, suspend, integration) {
			this.suspendAlert = suspend;
			this.publishAlert = publish;
			this.showAlert.active = true;
			this.showAlert.data = integration;
		},
		openAlertDelete(integration) {
			if(integration.status == 'Publicado') {
				this.$store.commit('activeAlert', {
					message: `A integração "${integration.titulo}" está publicada e não pode ser excluída.`,
					status: 'error',
				});
				return;
			}
			this.suspendAlert = false;
			this.publishAlert = false;
			this.showAlert.active = true;
			this.showAlert.data = integration;
		},
		closeAlert() {
			this.showAlert.active = false;
			this.showAlert.data = {};
			this.suspendAlert = false;
			this.publishAlert = false;
		},

		async publishIntegration(data) {
			try {
				this.closeAlert();
				this.$store.commit('activeAlert', {
					message: `Iniciamos a publicação da integração, isso poderá levar alguns minutos. Informaremos quando concluímos.`,
					status: 'info',
				});

				await this.restApi().publishIntegration(data.id);

				this.initPageIntegrations();
				this.$store.commit('activeAlert', {
					message: `A integração "${data.titulo}" foi publicada com sucesso.`,
				});
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: `Falha ao publicar integração, tente novamente. ${error.notifications}`,
					status: 'error',
				});
			}
		},
		async suspendIntegration(data) {
			try {
				await this.restApi().suspendIntegrationPublished(data.id);

				this.closeAlert();
				this.initPageIntegrations();
				this.$store.commit('activeAlert', {
					message: `A integração "${data.titulo}" foi suspensa com sucesso.`,
				});
			} catch (error) {
				this.$store.commit('activeAlert', {
					message:  error.notifications,
					status: 'error',
				});
			}
		},
		async deleteIntegration(data) {
			try {
				await this.restApi().deleteIntegrations(data.id);

				this.closeAlert();
				this.initPageIntegrations();
				this.$store.commit('activeAlert', {
					message: `A integração "${data.titulo}" foi excluída com sucesso.`,
				});
			} catch (error) {
				this.$store.commit('activeAlert', {
					message:  error.notifications,
					status: 'error',
				});
			}
		},

		async filterBySearch(search) {
			this.filtering = search.length < 1 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length < 1 ? '' : search);
		},
		async filterByResult(query) {
			this.queryResults = query;
			this.addOrRemoveFilter(this.filtersActive, 'filterResults', query);
			this.filtering = !this.queryResults.length;
		},
		async filterTable(filters) {
			try {
				this.isLoadingTable = true;
				const routeQuery = this.$route.query;
				const payload = {
					page: 1,
					size: this.pageSize,
					titulo: filters?.filterSearch || routeQuery?.titulo,
					status: filters?.filterResults || routeQuery?.status,
				};

				this.$router.replace({query: { ...payload }}).catch(() => {});

				const dataFilter = await this.restApi().getIntegrations(payload);
				await this.applyFilter(dataFilter, payload);

			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
				this.clearfiltering = true;
			} finally {
				this.isLoadingTable = false;
			}
		},
		async applyFilter(dataFiltered, query) {
			this.filtering = true;
			this.clearfiltering = true;
			this.scrollInfinite = false;
			this.listIntegrations = dataFiltered;

			if(this.listIntegrations?.length >= 15) {
				this.scrollInfinite = true;
			}

			WebStorage.setItem('setFilters', query);
			WebStorage.setItem('applyPage', 'integrations');
		},
		async clearFilter() {
			this.filtering = false;
			this.filtersActive = {};
			this.scrollInfinite = true;
			this.clearfiltering = false;
			this.queryResults = [];

			await this.$router.replace({'query': null}).catch(() => {});

			WebStorage.removeItem('applyPage');
			WebStorage.removeItem('setFilters');
			this.initPageIntegrations();
    },
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		async initPageIntegrations() {
			this.isLoadingPage = true;

			if(Object.keys(this.$route.query).length) {
				await this.filterTable();
			} else {
				await this.getIntegrations();
			}
			this.isLoadingPage = false;
		},
		async getIntegrations() {
			this.isLoadingTable = true;
			const payload = {
				...this.$route.query,
				page: 1,
				size: this.pageSize,
			};

			try {
				this.listIntegrations = await this.restApi().getIntegrations(payload);
				this.listIntegrationsTemp = this.listIntegrations;

				if(this.listIntegrations?.length < 15) {
					this.scrollInfinite = false;
				}
			} catch (error) {
				this.$store.commit('activeAlert', {
					message:  error.notifications,
					status: 'error',
				});
			}

			this.isLoadingTable = false;
		},
		async paginations() {
			this.page++;

			const payload = {
				page: this.page,
				size: this.pageSize,
				...this.$route.query,
			};

			try {
				const response = await this.restApi().getIntegrations(payload);
				this.listIntegrations.push(...response);
				this.listIntegrationsTemp = this.listIntegrations;

				if (response.length < 15) {
					this.scrollInfinite = false;
				}
			} catch (error) {
				this.$store.commit('activeAlert', {
					message:  error.notifications,
					status: 'error',
				});
			}
		},

		goToCreateIntegration() {
			this.$router.push({
				name: 'createIntegration',
			});
		},
		goToEditIntegration(id) {
			this.$router.push({
				name: 'editIntegration',
				params: { id },
			});
		},
		goToStoreDetailling(id) {
			this.$router.push({
				name: 'knowMoreIntegration',
				params: { id },
			});
		},
		async goToDetailling(id) {
			this.$router.push({
				name: 'integrationDetailing',
				params: { id },
			});
		},
	},
	beforeCreate() {
		const applyPage = WebStorage.getItem('applyPage');

		if(applyPage == 'integrations') {
			this.$router.replace({query: { ...WebStorage.getItem('setFilters') }}).catch(() => {});
		};
	},
	async created() {
		this.isLoadingPage = true;
		//await this.validationAccessIntegrations();
		await this.initPageIntegrations();
		this.isLoadingPage = false;
	},
	mounted() {
	},
});
