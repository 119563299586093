import { render, staticRenderFns } from "./ProfileDetailing.vue?vue&type=template&id=f63c7690&scoped=true&"
import script from "./ProfileDetailing.vue?vue&type=script&lang=ts&"
export * from "./ProfileDetailing.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileDetailing.vue?vue&type=style&index=0&id=f63c7690&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f63c7690",
  null
  
)

export default component.exports