
import Table from '@/components/Table.vue';
import FilterTable from '@/components/FilterTable.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';

import TreeFunctionality from '@/components/TreeFunctionality.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import NotificationPage from '@/components/NotificationPage2.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

import { mapActions, mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';
import { PERMISSION_IDS, INACTIVE_USER_MODAL_KEY } from '@/constants';
import WebStorage from '@/common/WebStorage';
import { userProfilePermission } from '@/components/mixins/permissions/userProfile';
import { searchPermissions, removeItemFromArrayByValue } from '@/components/helpers';

export default mixins(
	userProfilePermission

).extend({
	name: 'ProfileDetailing',
	components: {
		BackgroundView,
		ModalButtonSuccess,
		AlertNotificationModal,
		NotificationPage,
		ModalButtonCancel,
		TreeFunctionality,
		Table,
		FilterTable,
		SelectFilter,
		RoundPhotoFrame,
	},
	data() {
		return {
			page: 1,
			userId: '',
			activeTab: 'permission',
			currentProfile: '',
			isLoadingPage: true,
			showAlert: false,
			showDelete: false,
			showNotification: false,

			editUsers: true,
			activeUser: true,
			sendEmails: true,
			permissionIds: PERMISSION_IDS,
			organizationInfo: JSON.parse(WebStorage.getItem('accountInfo')),

			usersActive: [],
			scrollInfinite: true,
			isLoadingTable: false,
			filtering: false,

			dataTable: [],
			dataTableTemp: [],
			dataTableFilteredByName: [],
			dataTableFilteredByEmail: [],
			applyedFilters: [],
		};
	},

	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),
		titleButtonToggleActiveProfile() {
			if (this.currentProfile.statusActive) {
				return 'Inativar';
			} else {
				return 'Ativar';
			}
		},
		alertNotificationTitle() {
			return this.showDelete ? 'Exclusão do Perfil' : 'Inativar Perfil de Usuário';
		},
		alertNotificationDescription() {
			return this.showDelete ? `Ao excluir o perfil todos os usuários vinculados a ele perderão sua permissões à organização <b>${ this.organizationInfo?.name }</b>. O que deseja fazer ?` : `Inativar o perfil todos os usuários vinculados a ele perderão suas permissões à organização <b>"${ this.organizationInfo?.name }"</b>. O que deseja fazer?`;
		},
		keepTextModal() {
			return this.showDelete ? 'Manter Perfil' : 'Manter Perfil de Usuário Ativo';
		},
		confirmTextModal() {
			return this.showDelete ? 'Excluir Perfil' : 'Inativar Perfil de Usuário';
		},

		notificationFirstText() {
			return this.currentProfile.statusActive ? `O perfil de usuário <b>"${this.currentProfile.productProfile.description}"</b>, foi inativado.`
			: `O perfil de usuário <b>"${this.currentProfile.productProfile.description}"</b>, foi ativado e passou a ter acesso à <b>${ this.organizationInfo?.name }</b>.`;
		},

		notificationTitle() {
			return this.currentProfile.statusActive ? 'Perfil de Usuário Inativado' : 'Perfil de Usuário Ativado';
		},
	},
	methods: {
		...mapActions('aliareAccount', ['getProfileDetailingById', 'getUsersProfileDetailingById', 'verifyUserActive', 'activeProfile',
		'getProfilesByIdV2', 'deleteProductProfile', 'disableProfile']),
		...mapActions('auth', ['revokeTokens', 'sendConfirmationEmail']),

		async filterTable(data) {
			const dataFilter = {
				currentFilter: data.currentFilter,
				prevSearch:  data.filterSearch || this.dataTableFilteredByName,
				usersEmail:  data.filterEmail || this.dataTableFilteredByEmail,
				applyedFilters: this.applyedFilters,

				getData: async(data) => {
					return this.getUsers(data);
				},
			};

			const resultDataFilter = await filter(dataFilter);

			this.applyFilter(dataFilter.currentFilter, resultDataFilter.dataFiltered);
			return {
				success: true,
				dataFilter,
				dataFiltered: resultDataFilter.dataFiltered,
			};
		},
		async filterByUserName(dataFilter) {
			this.isLoadingPage = true;
			const statusFilter = await this.filterTable({currentFilter: 'filterByUserName', filterSearch: dataFilter});
			this.isLoadingPage = false;

			if (!statusFilter?.dataFiltered?.length) {
				removeItemFromArrayByValue(this.applyedFilters, 'filterByUserName');
			} else if (statusFilter.success) {
				this.dataTableFilteredByName = dataFilter;
			}
		},
		async verifyUsersActive(users) {
			const vm = this;
			let usersAble = [];

			for await (const user of users) {
				if (vm.usersActive.find(userActive => userActive.tenantUserId == user.tenantUserId)) {
					usersAble.push(user);
				} else {
					await this.verifyUserActive({userId: user.id}).then((response) => {
						if (response) {
							let indexUser = users.findIndex(userTable => userTable.id === user.id);
							usersAble.push(users[indexUser]);
							this.usersActive.push(users[indexUser]);
						}
					});
				}
			};


			return usersAble;
		},
		async filterByUserEmail(dataFilter) {
			this.isLoadingTable = true;
			const statusFilter = await this.filterTable({currentFilter: 'filterByUserEmail', filterEmail: dataFilter});
			this.isLoadingTable = false;

			if (!statusFilter?.dataFiltered.length) {
				removeItemFromArrayByValue(this.applyedFilters, 'filterByUserEmail');
			} else if (statusFilter.success) {
				this.dataTableFilteredByEmail = dataFilter;
			}
		},
		async applyFilter(currentFilter, dataFiltered) {
			this.filtering = true;
			this.dataTable = dataFiltered.data;

			if (!this.applyedFilters.includes(currentFilter)) {
				this.applyedFilters.push(currentFilter);
			}
		},

		goToEditProfile() {
			const permissionId = this.$route.params.permissionId;

			this.$router.push({
				name: 'editProfile',
				params: { permissionId },
			}).catch(()=>{});
		},

		// async pagination() {
		// 	this.page++;
		// 	const permissionId = this.$route.params.permissionId;

		// 	const payload = { page: this.page, permissionId };
		// 	await this.getUsersProfileDetailingById(payload).then((response) => {
		// 		for (const index in response?.users) {
		// 			this.dataTable.push(response?.users[index]);
		// 		}

		// 		if (response?.users.length < 10) {
		// 			this.scrollInfinite = false;
		// 		}
		// 	});
		// },
		onTabSelect(tab: string) {
			this.activeTab = tab;
		},
		async loadCurrentProfile() {

			const permissionId = this.$route.params.permissionId;
			await this.getProfileDetailingById({permissionId}).then((profileInfo) => {
				this.currentProfile = profileInfo;
			});
			await this.getProfilesByIdV2({id: permissionId}).then((profileInfoV2) => {
				this.currentProfile.statusActive = profileInfoV2.active;
			});
		},

		openModalAlert() {
			this.showDelete = false;
			this.showAlert = true;
		},

		async inactiveProfile() {
			const permissionId = this.$route.params.permissionId;

			try {

				await this.disableProfile({ permissionId });
				this.showNotification = true;

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.notifications[0],
					status: 'error',
				});
			}
		},

		async activeProfileUser() {
			const permissionId = this.$route.params.permissionId;

			try {

				await this.activeProfile({ permissionId });
				this.showAlert = false;
				this.showNotification = true;

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.notifications[0],
					status: 'error',
				});
			}
		},

		async loadUsersInProfile() {
			this.isLoadingTable = true;
			const permissionId = this.$route.params.permissionId;
			await this.getUsersProfileDetailingById({permissionId}).then(async (usersProfile) => {
				let usersActive = await this.verifyUsersActive(usersProfile.users);
				this.dataTable = usersActive;

				if (usersProfile.users.length < 10) {
					this.scrollInfinite = false;
				}

				this.dataTableTemp = this.dataTable;

			});


			this.isLoadingTable = false;
		},
		async deleteProfile(profile) {
			try {

				await this.deleteProductProfile(profile.id);

				this.$store.commit('activeAlert', {
					message: `O perfil ${profile.description} foi excluído com sucesso`,
				});

				this.showAlert = false;
				this.$router.push({	name: 'user-profiles' });

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: `Falha ao excluir o perfil ${profile.description}, tente novamente`,
					status: 'error',
				});
			}
		},

		goToProfileDetailing() {
			window.location.reload();
			this.showNotification = false;
			this.showAlert = false;
		},

		goToProfiles() {
			this.$router.push({
				name: 'user-profiles',
			}).catch(()=>{});

			this.showNotification = false;
			this.showAlert = false;
		},
	},
	async created() {
		this.isLoadingPage = true;

		this.loadUsersInProfile();
		await this.loadCurrentProfile();

		this.isLoadingPage = false;

	},
});
