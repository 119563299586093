
import MenuOptions from '@/components/MenuOptions.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';
import TreeFunctionality from '@/components/TreeFunctionality.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import NotificationPage from '@/components/NotificationPage2.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { mapActions, mapGetters } from 'vuex';
import { INACTIVE_USER_MODAL_KEY } from '@/constants';
import { userPermission } from '@/components/mixins/permissions/user';
import { searchPermissions } from '@/components/helpers';

export default mixins(
	userPermission
).extend({
	name: 'UserDetailing',
	components: {
		MenuOptions,
		BackgroundView,
		RoundPhotoFrame,
		NotificationPage,
		TreeFunctionality,
		ModalButtonCancel,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	data() {
		return {
			userId: '',
			mode:'inactive',
			activeTab: 'registrationData',
			currentUser: '',
			isLoading: true,
			showAlert: false,
			showNotification: false,
			organizationInfo: JSON.parse(WebStorage.getItem('accountInfo')),
		};
	},
	async mounted() {
		try {
			await this.loadCurrentUser();

			if(!this.canViewDetailUser()) {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('permission.noPermissionFuncionality'),
					status: 'error',
				});
				this.$router.push({
					name: 'users',
				});
			}
		}	catch (e) {
			this.$store.commit('activeAlert', {
				message: this.$locale.baseText('user.errorOnLoadingUser'),
				status: 'error',
			});
		}
	},
	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),

		alertNotificationDescription() {
			return `Inativar o usuário fará com a pessoa perca seu acesso e suas permissões à organização <b>"${ this.organizationInfo?.name }"</b>. O que deseja fazer?`;
		},

		notificationTitle() {
			return this.currentUser.permission?.activePermission ? 'Inativação do Usuário' : 'Usuário Ativado';
		},

		notificationFirstText() {
			return this.currentUser.permission?.activePermission ? `O usuário <b>${ this.currentUser?.people?.legalName }</b>, de e-mail <b>${ this.currentUser?.email }</b>, foi inativado.`
			: `O usuário <b>${ this.currentUser?.people?.legalName }</b>, de e-mail <b>${ this.currentUser?.email }</b>, foi ativado e passou a ter acesso à
							<b>${ this.organizationInfo?.name }</b>.`;
		},

		notificationSecondText() {
			return `<b>${ this.currentUser?.people?.legalName }</b> não terá mais acesso à <b>${ this.organizationInfo?.name }</b>, até que seja ativado novamente. Caso o usuário tenha acesso à outras organizações, ele(a) não perderá suas permissões relacionadas a elas.`;
		},

	},
	methods: {
		...mapActions('aliareAccount', [
			'peopleId', 'getUserId', 'activeUser', 'disableUser','getUserAccountId',
			'verifyUserActive', 'getProfilesByTenantIdV2', 'getProfileDetailingById', 'getUserPermissionById',
		]),
		...mapActions('auth', ['revokeTokens', 'sendConfirmationEmail']),

		goToUserDetailing() {
			window.location.reload();
			this.showNotification = false;
			this.showAlert = false;
		},

		goToUsers() {
			this.$router.push({
				name: 'users',
			}).catch(()=>{});

			this.showNotification = false;
			this.showAlert = false;
		},

		onTabSelect(tab: string) {
			this.activeTab = tab;
		},
		async loadCurrentUser() {
			this.isLoading = true;
			this.userId = this.$route.params.id;
			let userPermissionActive = '';
			let profile = '';

			const userInfo = await this.getUserId(this.userId);
			const usersPermission = await this.getUserPermissionById({ userId: this.userId});
			const peopleInfo = userInfo.personId && await this.peopleId(userInfo.personId);

			usersPermission.forEach((permission) => {
				if (!userPermissionActive.activePermission) { // Verifique se ainda não encontramos uma permissão ativa
					if (!permission.userProfile.blocked && permission.userProfile.active) {
						userPermissionActive = permission;
						userPermissionActive.activePermission = true;

					} else {
						userPermissionActive = permission;
						userPermissionActive.activePermission = false;
					}
				}
			});

			if(userPermissionActive !== ''){
				profile = await this.getProfileDetailingById({permissionId: userPermissionActive.permission.id});
			}

			this.currentUser = userInfo;
			this.currentUser.people = peopleInfo;
			this.currentUser.profile = profile;
			this.currentUser.permission = userPermissionActive;

			this.isLoading = false;
		},

		goToEditUser(id) {
			WebStorage.setItem('profileName', this.currentUser.profile.productProfile.description);

			this.$router.push({
				name: 'editUser',
				params: { id },
			}).catch(()=>{});
		},
		addAccent(word) {
			const accentMap = {
				'Fisica': 'Física',
				'Juridica': 'Jurídica'
			};

			if (word in accentMap) {
				return accentMap[word];
			}

			return word;
		},
		date(data) {
			const newDate = new Date(data);
			const novaData = `${newDate.toLocaleDateString()} às ${newDate.toLocaleTimeString()}`;
			return novaData;
		},
		formatPhone(phone) {
			if(!phone) return '-';

			phone = phone?.replace(/\D/g, '');
			return phone = `+${phone?.slice(0, 2)} (${phone?.slice(2, 4)}) ${phone?.slice(4, 9)}-${phone?.slice(9)}`;
		},
		formatCPF(cpf) {
			cpf = cpf?.replace(/\D/g, '');
			cpf = cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
			return cpf;
		},
		formatCNPJ(cnpj) {
			cnpj = cnpj?.replace(/\D/g, '');
			cnpj = cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
			return cnpj;
		},
		sendEmail(email) {
			this.sendConfirmationEmail(email)
			.then(() => {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('sendEmail.emailSucces'),
				})
			})
			.catch((error) => {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('sendEmail.emailError'),
					status: 'error',
				});
			});
		},
		async disableUserModal(user) {
			try {
				await this.disableUser(user.permission.userProfile.id);
				this.mode = 'inactive';
				this.showNotification = true;

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.notifications[0],
					status: 'error',
				});
			}
		},
		async activatedUser(user) {
			try {
				await this.activeUser(this.currentUser.permission?.userProfile?.id);
				this.mode = 'active';
				this.showNotification = true;

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.notifications[0],
					status: 'error',
				});
			}
		},
	},
});
